<template>
  <div>
    <!-- 头部 -->
    <!-- <van-cell center is-link to="#" value="切换就诊卡"> -->
    <van-cell center class="van-cell_herder">
      <!-- 使用 title 插槽来自定义标题 -->
      <template slot="title">
        <span class="custom-title">{{ userName }}</span>
      </template>
      <template slot="label">
        <span>{{ cardNo }}</span>
      </template>
      <template slot="default">
        <!-- <van-button type="default" text="显示遮罩层" icon="qiehuan" icon-color="#57c4b7" @click="showOverlay = true" /> -->
        <span @click="onqiehuan">切换用户</span>
      </template>
      <van-icon
        slot="right-icon"
        class="iconfont"
        @click="onqiehuan"
        class-prefix="icon"
        name="qiehuan"
        color="#57c4b7"
        style="margin-left: 2%"
      />
    </van-cell>
    <van-cell>
      <template slot="title">
        <span style="color: #57c4b7"
          >*温馨提示：纸质报告请就近到自助打印工作站进行打印，微信医院仅供查看且只能查看近一个月的报告</span
        >
      </template>
    </van-cell>

    <!-- 中间 -->
    <div class="regilist_center">
      <van-cell-group>
        <div style="width: 100%">
          <van-cell center>
            <template slot="icon">
              <van-dropdown-menu>
                <van-dropdown-item
                  v-model="value1"
                  :options="option1"
                  @change="medicalDrop"
                />
              </van-dropdown-menu>
            </template>
            <template slot="title">
              <div style="text-align: center">
                <span @click="showTime = true">{{ date }}</span>
                <!-- <van-calendar v-model="showTime" type="range" @confirm="onConfirm" :selected="startdate"  :min-date="minDate" :max-date="maxDate"/> -->
                <van-calendar
                  v-model="showTime"
                  type="range"
                  @confirm="onConfirm"
                  :defaultDate="defaultDate"
                  :min-date="minDate"
                  :max-date="maxDate"
                  color="#57c4b7"
                />
                <!-- <van-calendar v-model="showTime" type="range" /> -->
              </div>
            </template>
            <template slot="right-icon">
              <div style="margin-right: 3%">
                <van-dropdown-menu active-color="#1989fa">
                  <van-dropdown-item
                    v-model="value2"
                    :options="option2"
                    @change="sortDrop"
                  />
                </van-dropdown-menu>
              </div>
            </template>
          </van-cell>
        </div>
      </van-cell-group>
    </div>

    <!-- 列表 -->
    <div>
      <van-cell-group v-for="Ditem in lists" :key="Ditem.index">
        <van-list v-bind="Ditem">
          <div style="width: 100%">
            <van-cell center @click="onclickDetail(Ditem.reportID, Ditem.typeId)">
              <!-- <template slot="icon">
                      <img class="GHJL_list_header_img" src="@/assets/imagel/header.png" alt="">
                    </template> -->
              <template slot="title">
                <span>{{ Ditem.reportName }} </span>
                <span style="font-size: 12px; color: #57c4b7">
                  {{ Ditem.patientType }}</span
                >
                <!-- <span style="font-size: 12px"> | </span>
                  <span
                    v-if="Ditem.visitType === '0'"
                    style="font-size: 12px; color: #57c4b7"
                    >门诊</span
                  >
                  <span v-else style="font-size: 12px; color: #57c4b7"
                    >住院</span
                  > -->
              </template>
              <template slot="label">
                <span style="font-size: 12px; color: #57c4b7"
                  >检查时间：{{ Ditem.checkedDate }}</span
                >
              </template>
              <template slot="right-icon">
                <span v-if="Ditem.status === '0'" class="lable_cell_color_green"
                  >已出</span
                >
                <span v-else class="lable_cell_color_red">未出</span>
              </template>
            </van-cell>
          </div>
        </van-list>
      </van-cell-group>
    </div>

    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>@拓实银医</span>
    </div>
  </div>
</template>
<script>
import { Dialog, Toast } from "vant";
import ajax from "../../lib/ajax";
import store from "../../store";
// let listss = [];
var nowDate = new Date();
export default {
  data() {
    return {
      // header_height: 0,
      // showOverlay:false,
      date: "",
      startTime: "",
      endTime: "",
      startdate: nowDate,
      minDate: new Date(
        nowDate.getFullYear(),
        nowDate.getMonth() - 1,
        nowDate.getDate()
      ),
      maxDate: new Date(
        nowDate.getFullYear(),
        nowDate.getMonth(),
        nowDate.getDate()
      ),
      value1: 2,
      showTime: false,
      defaultDate: [],
      map:{},
      // lists:[],
      // lists:[{
      //   reportId:1,
      // }],
      lists: [],
      userName: "",
      cardNo: "",
      currentDate: new Date(),
      onclickChangecard: false,
      value2: 2,
      iserweima: false,
      option1: [
        {
          text: "全部",
          value: 2,
        },
        {
          text: "住院",
          value: 1,
        },
        {
          text: "门诊",
          value: 0,
        },
      ],
      option2: [
        {
          text: "默认排序",
          value: 2,
        },
        {
          text: "时间排序",
          value: 1,
        },
        // {
        //   text: "门诊",
        //   value: 0,
        // },
      ],
    };
  },
  created() {
    let username = store.getters["store/userName"];
    this.userName = username;
    this.cardNo = store.getters["store/cardNo"]; //声明就诊卡号
    // 初始化数据

    //初始化时间
    const startDate = new Date(new Date().setDate(new Date().getDate() - 7));
    const date = [startDate, new Date()];
    this.onConfirm(date);
    this.defaultDate = date;

    // this.getRegL();
    // this.medicalDrop();
    //初始化lists[]
    //  this.medicalDrop(0);
  },
  methods: {
    async getRegL() {
      // 异步更新数据
      // setTimeout(() => {
      //   for (let i = 0; i < 0; i++) {
      //     this.lists.push(this.lists.length);
      //   }
      //   // 加载状态结束
      //   this.loading = false;

      //   // 数据全部加载完成
      //   if (this.lists.length == this.lists.length) {
      //     this.finished = true;
      //   }
      // }, 500);
      //添加请求拦截器
      ajax.interceptors.request.use(
        (config) => {
          //在发送请求之前做某事，比如说 设置loading动画显示
          Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "加载中,请等待...",
          });
          return config;
        },
        (error) => {
          //请求错误时做些事
          return Promise.reject(error);
        }
      );

      //添加响应拦截器
      ajax.interceptors.response.use(
        (response) => {
          //对响应数据做些事，比如说把loading动画关掉
          Toast.clear();
          return response;
        },
        (error) => {
          //请求错误时做些事
          return Promise.reject(error);
        }
      );
      // alert("病历号："+patientid);
      // let idcard = store.getters["store/idcard"];
      let outpatientId = store.getters["store/patientid"];
      console.log(outpatientId);
      this.lists = [];
      await ajax
        .post("/Api/ReportService/GetLisReports", {
          // idCard: idcard,
          // begin: this.startTime,
          // end: this.endTime,
          // useQueryDate: true,
          // machineName: "wechat",
          userID: outpatientId,
          userName: this.userName,
          useQueryDate: false,
          machineName: "wechat",
        })
        .then((rep) => {
          let lis = rep.data.result.list;
          this.map = rep.data.result.map;
          for (let i = 0; i < lis.length; i++) {
            lis[i].typeId = 0;
            this.lists.push(lis[i]);
          }
          if (this.value1 == 1) {
            console.log("cs");
            this.lists = this.lists.filter(
              (item) => item.patientType == "住院"
            );
          }
          if (this.value1 == 0) {
            console.log("cs0");
            this.lists = this.lists.filter(
              (item) => item.patientType == "门诊"
            );
          }
          if (this.lists.length == 0) {
            Dialog.alert({
              title: "提示",
              message: "暂无您的报告记录",
            });
          }
          console.log(this.lists);
        })
        .catch((err) => {
          console.log(err.message);
          Toast("获取报告信息失败，请重试");
        });
      // await ajax
      //   .post("/Api/ReportService/GetPacsReports", {
      //     idCard: idcard,
      //     begin: this.startTime+" 00:00:00",
      //     end: this.endTime+" 23:59:59",
      //     useQueryDate: true,
      //     machineName: "wechat",
      //   })
      //   .then((res) => {
      //     let pacs = {};
      //     pacs = res.data.result.reportInfos;
      //     for (let i = 0; i < pacs.length; i++) {
      //       pacs[i].typeId = 1;
      //       pacs[i].report_date = pacs[i].report_date.substring(0,10);
      //       this.lists.push(pacs[i]);
      //     }
      //     console.log(this.lists);
      //     if(this.lists.length == 0){
      //        Dialog.alert({
      //       title: "提示",
      //       message:
      //         "暂无您的报告记录"
      //     });
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err.message);
      //     Toast("获取报告信息失败，请重试");
      //   });
    },

    //全部,已出,未出下拉菜单改变事假
    medicalDrop() {
      this.getRegL();
      // console.log(this.value1);

      // console.log(this.lists);

      this.sortDrop();
    },
    //默认排序下拉菜单改变事件
    sortDrop() {
      if (this.value2 == 0) {
        // this.selectDropTime();
        this.getDate();
      } else if (this.value2 == 1) {
        this.lists.sort(this.sortTime);
      }
    },
    //就诊排序
    // sortDepNumber(obj1, obj2) {
    //   return obj1.visitType - obj2.visitType;
    // },
    //时间排序
    sortTime(obj1, obj2) {
      return obj2.report_date - obj1.report_date;
    },
    //切换时间格式
    formatDate(date) {
      return (
        `${
          date.getMonth() >= 9
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)
        }` +
        "/" +
        `${date.getDate() > 10 ? date.getDate() : "0" + date.getDate()}`
      );
    },
    //日历改变事件
    onConfirm(date) {
      // console.log(date);
      const [start, end] = date;
      this.showTime = false;
      this.startTime =
        `${start.getFullYear()}` + "/" + `${this.formatDate(start)}`;
      this.endTime = `${end.getFullYear()}` + "/" + `${this.formatDate(end)}`;
      this.date = `${
        start.getMonth() >= 9
          ? start.getMonth() + 1
          : "0" + (start.getMonth() + 1)
      }-${start.getDate()}至${
        end.getMonth() >= 9 ? end.getMonth() + 1 : "0" + (end.getMonth() + 1)
      }-${end.getDate()}`;
      // return [start,end];
      this.medicalDrop();
    },
    //下拉全部菜单筛选、时间筛选、排序
    async selectDropTime() {
      // console.log(listss);
      this.lists = [];
      // if (this.value1 == 2) {
      //   for (let i = 0; i < listss.length; i++) {
      //     console.log(this.startTime, this.endTime, listss[i].reportTime);
      //     if (
      //       this.DateContrast(this.startTime, listss[i].reportTime) &&
      //       this.DateContrast(listss[i].reportTime, this.endTime)
      //     ) {
      //       let index = listss[i];
      //       this.lists[i] = index;
      //       // this.lists.push(index);
      //     }
      //   }
      // } else if (this.value1 == 1) {
      //   for (let i = 0; i < listss.length; i++) {
      //     if (
      //       listss[i].reportStatus === "1" &&
      //       this.DateContrast(this.startTime, listss[i].reportTime) &&
      //       this.DateContrast(listss[i].reportTime, this.endTime)
      //     ) {
      //       let index = listss[i];
      //       // this.lists.push(index);
      //       this.lists[i] = index;
      //     }
      //   }
      // } else if (this.value1 == 0) {
      //   for (let i = 0; i < listss.length; i++) {
      //     if (
      //       listss[i].reportStatus === "0" &&
      //       this.DateContrast(this.startTime, listss[i].reportTime) &&
      //       this.DateContrast(listss[i].reportTime, this.endTime)
      //     ) {
      //       let index = listss[i];
      //       // this.lists.push(index);
      //       this.lists[i] = index;
      //     }
      //   }
      // } else {
      //   Toast("出现未知错误!!!");
      // }
    },
    //时间比较
    DateContrast(start, end, flags) {
      const starttime = new Date(
        start.substring(0, 4),
        start.substring(4, 6),
        start.substring(6, 8)
      );
      const starttimes = starttime.getTime();
      const endTime = new Date(
        end.substring(0, 4),
        end.substring(4, 6),
        end.substring(6, 8)
      );
      const endTimes = endTime.getTime();
      if (endTimes >= starttimes) {
        flags = true;
        return flags;
      } else {
        flags = false;
        return flags;
      }
    },
    onclickDetail(checkId, typeId) {
      window.sessionStorage.setItem("starttime", this.startTime);
      window.sessionStorage.setItem("endtime", this.endTime);
      window.sessionStorage.setItem("checkId", checkId);
      // console.log(regStatus);
      // console.log(reportId);
      if (!typeId) {
        // alert(regNo);
        store.commit("store/setReportId", this.map);
        this.$router
          .replace({
            path: "/RepLI",
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // alert(regNo);
        store.commit("store/setReportId", this.map);
        this.$router
          .replace({
            path: "/RepPI",
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onqiehuan() {
      this.$router
        .replace({
          path: "/RpCardL",
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.regilist_center {
  .van-cell-group {
    // display: flex;
    .van-cell {
      margin: 0;
      padding: 0;
    }
  }
}
.right_icon_cell {
  display: none;
}
.lable_cell_color_green {
  font-size: 12px;
  color: #57c4b7;
}
.lable_cell_color_red {
  font-size: 12px;
  color: red;
}
.GHJL_list_header_img {
  height: 70px;
  padding: 4px;
}
</style>
